import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Navbar from "./components/Navbar"; // Import the styled Navbar
import Leaderboard from "./pages/Leaderboard";
import Drivers from "./pages/Drivers";
import RaceResults from "./pages/RaceResults";
import Admin from "./pages/Admin";
import AddResults from "./pages/AddResults";
import AddData from "./pages/AddData";
import Calendar from "./pages/Calendar";
import EditDrivers from "./pages/EditDrivers";
import Penalties from "./pages/Penalties";

// Container for the app content (to separate from Navbar)
const AppContainer = styled.div`
    padding-top: 60px; /* To account for the fixed navbar height */
`;

function App() {
  return (
    <Router>
      {/* Styled Navbar */}
      <Navbar />

      {/* App Content */}
      <AppContainer>
        <Routes>
          <Route path="/" element={<Leaderboard />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/race-results" element={<RaceResults />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/add-data" element={<AddData />} />
          <Route path="/add-results" element={<AddResults />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/edit-drivers" element={<EditDrivers />} />
          <Route path="/penalties" element={<Penalties />} />
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
