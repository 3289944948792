import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
    background-color: #111;
    color: #fff;
    font-family: "Formula1", Arial, sans-serif;
    padding: 20px;
    min-height: 100vh;
`;

const Title = styled.h1`
    color: #e10600;
    text-align: center;
    margin-bottom: 20px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #222;
`;

const TableHeader = styled.th`
    background-color: #e10600;
    color: #fff;
    padding: 10px;
    text-align: center;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #333;
    }

    &:hover {
        background-color: #444;
    }
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    border: 1px solid #444;
    color: #ddd;
`;

const Input = styled.input`
    background-color: #222;
    color: #fff;
    border: 1px solid #444;
    padding: 5px;
    width: 100%;
`;

const Select = styled.select`
    background-color: #222;
    color: #fff;
    border: 1px solid #444;
    padding: 5px;
    width: 100%;
`;

const SaveButton = styled.button`
    background-color: #e10600;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
        background-color: #c90000;
    }
`;

const EditDrivers = () => {
    const [drivers, setDrivers] = useState([]);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        // Fetch drivers
        axios.get("/api/drivers?season=2024").then((res) => setDrivers(res.data));

        // Fetch teams
        axios.get("/api/teams").then((res) => setTeams(res.data));
    }, []);

    const handleInputChange = (id, field, value) => {
        setDrivers((prevDrivers) =>
            prevDrivers.map((driver) =>
                driver.id === id ? { ...driver, [field]: value } : driver
            )
        );
    };

    const handleSave = async (id) => {
        const driver = drivers.find((driver) => driver.id === id);

        try {
            await axios.put(`/api/drivers/${id}`, {
                name: driver.name,
                team_id: driver.team_id,
            });
            alert("Driver updated successfully!");
        } catch (err) {
            console.error("Error updating driver:", err);
            alert("Failed to update driver.");
        }
    };

    return (
        <Container>
            <Title>Edytuj Kierowców</Title>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>ID</TableHeader>
                        <TableHeader>Imię i Nazwisko</TableHeader>
                        <TableHeader>Zespół</TableHeader>
                        <TableHeader>Na Gridzie</TableHeader> {/* New Column */}
                        <TableHeader>Akcje</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {drivers.map((driver) => (
                        <TableRow key={driver.id}>
                            <TableCell>{driver.id}</TableCell>
                            <TableCell>
                                <Input
                                    value={driver.name}
                                    onChange={(e) =>
                                        handleInputChange(driver.id, "name", e.target.value)
                                    }
                                />
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={driver.team_id || ""}
                                    onChange={(e) =>
                                        handleInputChange(driver.id, "team_id", e.target.value)
                                    }
                                >
                                    <option value="">-- Wybierz Zespół --</option>
                                    {teams.map((team) => (
                                        <option key={team.id} value={team.id}>
                                            {team.name}
                                        </option>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={driver.is_current_driver}
                                        onChange={(e) =>
                                            handleInputChange(driver.id, "is_current_driver", e.target.checked)
                                        }
                                    />
                                    Na Gridzie
                                </label>
                            </TableCell>
                            <TableCell>
                                <SaveButton onClick={() => handleSave(driver.id)}>Zapisz</SaveButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>

        </Container>
    );
};

export default EditDrivers;
