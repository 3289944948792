import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
    background-color: #111;
    color: #fff;
    padding: 20px;
    font-family: "Formula1", Arial, sans-serif;
    min-height: 100vh;
`;

const Title = styled.h1`
    color: #e10600;
    text-align: center;
    margin-bottom: 20px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #222;
`;

const TableHeader = styled.th`
    background-color: #e10600;
    color: #fff;
    padding: 10px;
    text-align: center;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #333;
    }

    &:hover {
        background-color: #444;
    }
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    border: 1px solid #444;
    color: #ddd;
`;

const Select = styled.select`
    background-color: #222;
    color: #fff;
    border: 1px solid #444;
    padding: 5px;
    width: 100%;
`;

const Input = styled.input`
    background-color: #222;
    color: #fff;
    border: 1px solid #444;
    padding: 5px;
    width: 100%;
`;

const Checkbox = styled.input`
    width: 20px;
    height: 20px;
    margin: auto;
`;

const RadioButton = styled.input`
    width: 20px;
    height: 20px;
    margin: auto;
`;

const SubmitButton = styled.button`
    background-color: #e10600;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
        background-color: #c90000;
    }
`;

const AddResults = () => {
    const [season, setSeason] = useState("2024"); // Default season
    const [drivers, setDrivers] = useState([]);
    const [races, setRaces] = useState([]);
    const [raceId, setRaceId] = useState("");
    const [results, setResults] = useState(
        Array(20).fill({ driver_id: null, fastest_lap: false, dnf: false, dsq: false, penalty_points: 0, position: null })
    );
    const [polePositionDriver, setPolePositionDriver] = useState("");

    useEffect(() => {
        // Fetch drivers and races whenever the season changes
        axios.get(`/api/drivers?season=${season}`).then((res) => setDrivers(res.data));
        axios.get(`/api/races?season=${season}`).then((res) => setRaces(res.data));
    }, [season]);

    const handleResultChange = (index, field, value) => {
        setResults((prevResults) =>
            prevResults.map((result, i) =>
                i === index
                    ? { ...result, [field]: value, position: index + 1 } // Automatically set position
                    : result
            )
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const filteredResults = results.filter((result) => result.driver_id !== null);

        try {
            await axios.post("/api/add-results", {
                race_id: raceId,
                results: filteredResults,
                pole_position_driver_id: polePositionDriver,
            });
            alert("Race results added successfully!");
        } catch (err) {
            console.error("Error adding race results:", err);
            alert("Failed to add race results.");
        }
    };

    return (
        <Container>
            <Title>Dodaj Wyniki Wyścigów</Title>

            {/* Select Race */}
            <label>
                Wybierz Wyścig:
                <Select value={raceId} onChange={(e) => setRaceId(e.target.value)} required>
                    <option value="">-- Wybierz Wyścig --</option>
                    {races.map((race) => (
                        <option key={race.id} value={race.id}>
                            {race.name}
                        </option>
                    ))}
                </Select>
            </label>

            {/* Select Season */}
            <label>
                Sezon:
                <Select value={season} onChange={(e) => setSeason(e.target.value)}>
                    <option value="2024">2024</option>
                    {/* Add more seasons if necessary */}
                </Select>
            </label>

            {/* Results Table */}
            <Table>
                <thead>
                    <tr>
                        <TableHeader>Pozycja</TableHeader>
                        <TableHeader>Kierowca</TableHeader>
                        <TableHeader>Fastest Lap</TableHeader>
                        <TableHeader>DNF</TableHeader>
                        <TableHeader>DSQ</TableHeader>
                        <TableHeader>Pole Position</TableHeader>
                        <TableHeader>Punkty Karne</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, index) => (
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                                <Select
                                    value={result.driver_id || ""}
                                    onChange={(e) => handleResultChange(index, "driver_id", e.target.value)}
                                >
                                    <option value="">-- Wybierz Kierowcę --</option>
                                    {drivers.map((driver) => (
                                        <option key={driver.id} value={driver.id}>
                                            {driver.name}
                                        </option>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    type="checkbox"
                                    checked={result.fastest_lap || false}
                                    onChange={(e) =>
                                        handleResultChange(index, "fastest_lap", e.target.checked)
                                    }
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    type="checkbox"
                                    checked={result.dnf || false}
                                    onChange={(e) => handleResultChange(index, "dnf", e.target.checked)}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    type="checkbox"
                                    checked={result.dsq || false}
                                    onChange={(e) => handleResultChange(index, "dsq", e.target.checked)}
                                />
                            </TableCell>
                            <TableCell>
                                <RadioButton
                                    type="radio"
                                    name="polePosition"
                                    value={result.driver_id}
                                    checked={polePositionDriver === result.driver_id}
                                    onChange={() => setPolePositionDriver(result.driver_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <Input
                                    type="number"
                                    value={result.penalty_points || 0}
                                    onChange={(e) =>
                                        handleResultChange(index, "penalty_points", parseInt(e.target.value, 10))
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>

            <SubmitButton onClick={handleSubmit}>Wyślij Wyniki</SubmitButton>
        </Container>
    );
};

export default AddResults;
