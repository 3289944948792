import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
    background-color: #111;
    color: #fff;
    padding: 20px;
    font-family: "Formula1", Arial, sans-serif;
    min-height: 100vh;
`;

const Title = styled.h1`
    color: #e10600;
    text-align: center;
    margin-bottom: 20px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #222;
`;

const TableHeader = styled.th`
    background-color: #e10600;
    color: #fff;
    padding: 10px;
    text-align: center;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #333;
    }

    &:hover {
        background-color: #444;
    }
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    border: 1px solid #444;
    color: #ddd;

    ${({ penaltyPoints }) =>
        penaltyPoints >= 10 &&
        `
        border: 2px solid red;
    `}
`;

const Penalties = () => {
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        axios.get("/api/penalties").then((res) => setDrivers(res.data));
    }, []);

    return (
        <Container>
            <Title>Kary Kierowców</Title>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>ID</TableHeader>
                        <TableHeader>Kierowca</TableHeader>
                        <TableHeader>Zespół</TableHeader>
                        <TableHeader>Punkty Karne</TableHeader>
                        <TableHeader>Na Gridzie</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {drivers.map((driver) => (
                        <TableRow key={driver.driver_id}>
                            <TableCell>{driver.driver_id}</TableCell>
                            <TableCell>{driver.driver_name}</TableCell>
                            <TableCell>{driver.team_name || "Brak"}</TableCell>
                            <TableCell penaltyPoints={driver.penalty_points}>
                                {driver.penalty_points}
                            </TableCell>
                            <TableCell>
                                {driver.is_current_driver ? "Tak" : "Nie"}
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default Penalties;
