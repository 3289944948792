import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";



// Styled Components
const NavbarContainer = styled.nav`
    background-color: #222;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box; /* Ensures padding is included in the total width */
`;

const Logo = styled.h1`
    color: #e10600;
    font-family: "Formula1", Arial, sans-serif;
    font-size: 1.5em;
    margin: 0;
`;

const NavLinks = styled.ul`
    display: flex;
    list-style: none;
    gap: 20px;

    a {
        text-decoration: none;
        color: #fff;
        font-size: 1em;

        &:hover {
            color: #e10600;
        }
    }

    @media (max-width: 768px) {
        display: none; /* Hide links on smaller screens */
    }
`;

const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;

    @media (max-width: 768px) {
        display: flex; /* Show hamburger on smaller screens */
    }

    div {
        background-color: #fff;
        height: 3px;
        width: 25px;
        margin: 3px 0;
        transition: 0.3s ease;
    }
`;

const DropdownMenu = styled.ul`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    right: 10px; /* Ensure dropdown stays within the viewport */
    background-color: #222;
    list-style: none;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    width: max-content; /* Dropdown adjusts width to its content */

    a {
        display: block;
        text-decoration: none;
        color: #fff;
        padding: 10px;
        border-radius: 5px;

        &:hover {
            background-color: #e10600;
        }
    }
`;

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <NavbarContainer>
            <Logo>Lotniskowa Liga F1</Logo>

            {/* Regular Navbar Links */}
            <NavLinks>
                <li>
                    <Link to="/">Klasyfikacja</Link>
                </li>
                <li>
                    <Link to="/drivers">Kierowcy</Link>
                </li>
                <li>
                    <Link to="/race-results">Wyniki Wyścigów</Link>
                </li>
                <li>
                    <Link to="/penalties">Kary</Link>
                </li>

                <li>
                    <Link to="/calendar">Kalendarz</Link>
                </li>
                <li>
                    <Link to="/admin">Admin</Link>
                </li>
            </NavLinks>

            {/* Hamburger Menu */}
            <Hamburger onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </Hamburger>

            {/* Dropdown Menu for Small Screens */}
            <DropdownMenu isOpen={isOpen}>
                <li onClick={closeMenu}>
                    <Link to="/">Klasyfikacja</Link>
                </li>
                <li onClick={closeMenu}>
                    <Link to="/drivers">Kierowcy</Link>
                </li>
                <li onClick={closeMenu}>
                    <Link to="/race-results">Wyniki Wyścigów</Link>
                </li>
                <li onClick={closeMenu}>
                    <Link to="/penalties">Kary</Link>
                </li>
                <li onClick={closeMenu}>
                    <Link to="/calendar">Kalendarz</Link>
                </li>
                <li onClick={closeMenu}>
                    <Link to="/admin">Admin</Link>
                </li>
            </DropdownMenu>
        </NavbarContainer>
    );
};

export default Navbar;
